body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.App-header a {
  color: white;
}


.logbox {
  margin-top: 30px;
  font-size: 10px;
  background: aliceblue;
  max-height: 300px;
  overflow-y: scroll;
}

.dark-bg {
  background-color: #4d4d4f;
}

.theme-bg {
  background-color: #44c8f5;
}

.theme {
  color: #44c8f5;
}

.white {
  color: white !important;
}

.main-nav li {
  display: inline-block;
  margin-right: 20px;
}
